@import url(https://fonts.googleapis.com/css2?family=Ubuntu&display=swap);
:root {
  --main-color: #395B64;
  --darkShade: #3b4d61;
  --dark-main: #2e4950;
  --light-main: #4d6b74;
  --accent: #f4f4f4;
  --art_color: #FFD32D;
}
body {
	font-family: Ubuntu,sans-serif;
	font-size: 0.9rem;
	color: var(--accent);
	line-height: 1.5em
}
.colorYellow{
  color: var(--art_color);
}
.bg-main-color{
  background-color: var(--main-color);
  color: var(--accent);
}
.bg-dark-main{
  background-color: var(--dark-main);
  color: var(--accent);
}
.bg-main-light{
  background-color: var(--light-main);
}
.btn-bg-main-color{
  background-color: var(--dark-main);
  color: var(--accent);
  border: 1px solid var(--dark-main);
}
.btn-bg-main-color.disabled, .btn-bg-main-color:disabled{
  background-color: var(--dark-main);
  color: var(--accent);
  border: 1px solid var(--dark-main);
}
.text-greyLight{
  color: var(--bs-gray);
}
.iNote_navbar_Items li.nav-item {
  padding: 0;
  background: var(--main-color);
  color: var(--accent);
}
ul.iNote_navbar_Items {
  list-style: none;
  padding: 0;
}
.iNote_navbar_Items li.nav-item a {
  text-decoration: none;
  color: var(--accent);
  padding: 10px;
  display: block;
  margin: 0 15px 10px 15px;
  background: var(--dark-main);
}
a.iNote_nav-link.active {
  border-left: 5px solid var(--accent);
}
.iNote_navbar .navbar-brand {
  font-size: 2rem;
  color: var(--accent);
  display: block;
  padding: 10px;
  line-height: 1.2;
}
.NotesWRap_Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
.NotesWrap .card {
  border: none;
  background: var(--dark-main);
}
.userAuth a {
  background: var(--main-color);
  margin: 0 15px 10px 15px;
}
.userAuth a:focus {
  box-shadow: none;
}
.userAuth {
  display: flex;
  flex-direction: column;
}
.btn.btn-sm-iNote {
  width: 35px;
  height: 35px;
  padding: 0;
  background: var(--main-color);
  border-radius: 0;
  margin: 2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
button.btn.btn-sm-iNote i {
  color: var(--accent);
  font-size: 1rem;
  line-height: 1.2rem;
}
h3.colTitle {
  font-size: 1.25rem;
  color: var(--accent);
  display: block;
  margin: 0;
}
.headerNotes {
  padding: 10px 0;
}
.NotesWrap .card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.userAuth button {
  background: var(--main-color);
}
.profileView {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
.dotsWrap {
  display: flex;
  flex-direction: column;
}
.dotsWrap i {
  font-size: 0.5rem;
  margin-bottom: 2px;
}

footer.main-footer {
  margin-top: 25px;
  padding: 10px 15px;
  text-align: center;
  background-color: var(--dark-main);
}
.appWrap {
  min-height: 100vh;
}

.modalFooter button {
  flex: 1;
  border-radius: 0;
  margin: 0 5px;
}

.modalFooter {
  display: flex;
  padding: 10px;
}
.row.notesListView {
  height: 100vh;
  overflow-y: scroll;
}

.notesListView::-webkit-scrollbar {
  width: 0.4rem;
}
.notesListView::-webkit-scrollbar-thumb {
  background-color: var(--dark-main);
}

::-webkit-scrollbar {
  width: 0.4rem;
}
::-webkit-scrollbar-thumb {
  background-color: var(--accent);
}